import React from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import queryString from 'query-string'
import moment from 'moment'

import InfiniteScroll from '../InfiniteScroll/dynamic'
import ProfileMenu from '../ProfileMenu/dynamic'
import Notification from '../Notification/dynamic'

import HeaderStyled from './styled'
import LogoImgSrc from '../../assets/images/header/logo-fpt.svg'
import NotificationImgSrc from '../../assets/images/header/noti.svg'
import NotificationRedImgSrc from '../../assets/images/header/noti-red.svg'
import HamburgurMenuImgSrc from '../../assets/images/header/hambeger-menu.svg'
import LanguageIcon from '../../assets/images/header/language.svg'
import BackIcon from '../../assets/images/header/back.svg'

import { findMatchPermissionsAtLeastSuperUser, capitalize } from '../../utils'
import { HUMAN_INCIDENCE, VEHICLE_INCIDENCE } from '../Notification'
import { INCIDENT_PAGE_PATH, TRAFFIC_PAGE_PATH } from '../../routes'

import { withTranslation } from '../../../i18n'

export class Header extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showListNotification: false,
      showBadge: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('touchend', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('touchend', this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (this.notificationList && !this.notificationList.contains(event.target)) {
      this.handleHideNotificationList()
    }
  }

  handleLogoutClicked = () => {
    this.props.openLogoutModal()
  }

  handleChangeLanguage = language => {
    this.props.onLanguageChanged(language)
  }

  handleLogoClicked = () => {
    Router.push(`${TRAFFIC_PAGE_PATH}`)
  }

  getLogoContainer() {
    return (
      <div className="logo-container">
        <div id="hamburgur-menu" data-test="hamburgur-menu" className="hamburgur-menu-container" onClick={this.props.onHamburgerClick}>
          <img src={HamburgurMenuImgSrc} />
        </div>
        <div id="logo" data-test="logo" className="logo" onClick={this.handleLogoClicked}>
          <img src={LogoImgSrc} />
        </div>
      </div>
    )
  }

  getUserName() {
    const popupOptions = [
      {
        label: this.props.t('language'),
        onClickFunction: this.handleChangeLanguage,
        icon: LanguageIcon,
        subMenu: {
          title: this.props.t('choose_your_language'),
          icon: BackIcon,
          options: [
            { text: 'English', value: 'EN' },
            { text: 'ภาษาไทย', value: 'TH' },
          ],
        },
        permissions: [],
      },
    ]
    const preparedPopupOptions = popupOptions.filter(optionData => {
      const matchPermissions = findMatchPermissionsAtLeastSuperUser(optionData.permissions, this.props.user.permissions)
      return matchPermissions.length > 0
    })
    return (
      <div className="username-wrapper">
        <ProfileMenu
          user={this.props.user}
          popupOptions={preparedPopupOptions}
          handleLogoutClicked={this.handleLogoutClicked}
          handleProfileClicked={this.props.onHamburgerClick}
          isLeftbarExpand={this.props.isLeftbarExpand}
          language={this.props.language}
        />
      </div>
    )
  }

  handleShowListNotification = () => {
    if (this.props.isLeftbarExpand) this.props.onHamburgerClick()
    this.props.onHideNotificationBadge()
    this.setState(state => ({
      showListNotification: !state.showListNotification,
    }))
  }

  handleHideNotificationList = () => {
    this.setState({
      showListNotification: false,
    })
  }

  handleNotificationClicked = notificationData => {
    const query = queryString.stringify({
      date: moment(notificationData.metadata.timestamp).format('YYYY-MM-DD'),
      zone: notificationData.metadata.zoneId,
      loadHeatMap: true,
    })
    Router.push(`${INCIDENT_PAGE_PATH}?${query}`)
    this.handleHideNotificationList()
  }

  getNotificationContent() {
    let output = this.getBlankNotification()
    if (this.props.listNotification.length > 0) {
      const latestNotificationData = this.props.listNotification[0]
      const earlierListNotification = [...this.props.listNotification.slice(1, this.props.listNotification.length)]
      let latestNotification = null
      latestNotification = (
        <>
          <div className="header-notification-list-sub-head-title-container">{this.props.t('new')}</div>
          <Notification
            id="new-notification"
            className="header-notification"
            incidenceType={latestNotificationData.metadata.incidentType}
            message={`Unauthorized access ${latestNotificationData.metadata.zoneName}`}
            timestamp={latestNotificationData.metadata.timestamp}
            title={`${capitalize(latestNotificationData.metadata.incidentType)} Incident`}
            onClick={() => this.handleNotificationClicked(latestNotificationData)}
          />
        </>
      )
      const listEarlier = earlierListNotification.map(notificationData => {
        return (
          <Notification
            id={`notification-${notificationData.id}`}
            key={notificationData.id}
            className="header-notification"
            incidenceType={notificationData.metadata.incidentType}
            message={`Unauthorized access ${notificationData.metadata.zoneName}`}
            timestamp={notificationData.metadata.timestamp}
            title={`${capitalize(notificationData.metadata.incidentType)} Incident`}
            onClick={() => this.handleNotificationClicked(notificationData)}
          />
        )
      })
      const listEarlierNotification = (
        <>
          <div className="header-notification-list-sub-head-title-container">{this.props.t('earlier')}</div>
          {listEarlier}
        </>
      )
      output = (
        <>
          {latestNotification}
          {listEarlierNotification}
        </>
      )
    }
    return output
  }

  getBlankNotification() {
    return (
      <div id="list-no-notification" data-test="list-no-notification" className="header-notification-list-no-data">
        No Incident
      </div>
    )
  }

  getNotification() {
    const imgSrc = this.state.showListNotification ? NotificationRedImgSrc : NotificationImgSrc
    const badge = <div id="header-notification-badge" data-test="header-notification-badge" className="badge" />
    let className = 'header-notification-list-container'
    className += this.state.showListNotification ? ' open' : ''
    return (
      <div
        id="header-notification"
        data-test="header-notification"
        ref={ref => {
          this.notificationList = ref
        }}
        className="header-notification-container"
      >
        <div id="notification-icon" data-test="notification-icon" className="icon-container" onClick={this.handleShowListNotification}>
          <img src={imgSrc} />
          {this.props.showNotificationBadge ? badge : null}
        </div>
        <InfiniteScroll id="list-notification-box" onMouseLeave={this.handleHideNotificationList} className={className}>
          <div className="header-notification-list-head-title-container">{this.props.t('notification')}</div>
          {this.getNotificationContent()}
        </InfiniteScroll>
      </div>
    )
  }

  getOptionPanelContainer() {
    const notificationPermissions = ['read:incident']
    const matchPermissions = findMatchPermissionsAtLeastSuperUser(notificationPermissions, this.props.user.permissions)
    const canSeeNotification = matchPermissions.length > 0
    return (
      <div className="option-panel-container">
        {canSeeNotification && this.getNotification()}
        {this.getUserName()}
      </div>
    )
  }

  render() {
    return (
      <HeaderStyled>
        {this.getLogoContainer()}
        {this.getOptionPanelContainer()}
      </HeaderStyled>
    )
  }
}

Header.defaultProps = {
  language: 'EN',
  user: {},
}

Header.propTypes = {
  language: PropTypes.string,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    nickname: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    locale: PropTypes.string,
    updated_at: PropTypes.string,
    sub: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    role: PropTypes.object,
    permissions: PropTypes.array,
  }),
  onHideNotificationBadge: PropTypes.func.isRequired,
  showNotificationBadge: PropTypes.bool.isRequired,
  listNotification: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      metadata: PropTypes.shape({
        incidentType: PropTypes.oneOf([HUMAN_INCIDENCE, VEHICLE_INCIDENCE]),
        timestamp: PropTypes.string.isRequired,
        zoneName: PropTypes.string.isRequired,
        zoneId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
    }),
  ).isRequired,
  onHamburgerClick: PropTypes.func.isRequired,
  openLogoutModal: PropTypes.func.isRequired,
  onLanguageChanged: PropTypes.func.isRequired,
  isLeftbarExpand: PropTypes.bool,
}

export default withTranslation()(Header)
