import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.header`
  width: calc(100% - 320px); /*320px is leftbar width*/
  height: 56px;
  position: absolute;
  left: 320px; /*320px is leftbar width*/
  background: #1a1a1a;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 28px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid #202020;
  padding: 0 24px;
  .logo-container {
    display: none;
    .hamburgur-menu-container {
      display: flex;
      width: 32px;
      height: 32px;
      margin-right: 16px;
      cursor: pointer;
    }
    .logo {
      width: 98px;
      height: 32px;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .option-panel-container {
    display: flex;
    align-items: center;
    height: 100%;
    .header-notification-container {
      position: relative;
      .icon-container {
        position: relative;
        width: 32px;
        height: 32px;
        cursor: pointer;
        .badge {
          position: absolute;
          top: 2px;
          right: 6px;
          width: 14px;
          height: 14px;
          background: #981214;
          border-radius: 50%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .header-notification-list-container {
        display: none;
        position: absolute;
        top: 50px;
        left: -270px;
        width: 304px;
        max-height: 470px;
        z-index: 10;
        background: #1e1e1e;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        overflow: auto;
        &.open {
          display: block;
        }
        .header-notification-list-no-data {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 300px;
          font-size: 18px;
          font-weight: 500;
          color: #403f45;
        }
        .header-notification-list-head-title-container {
          padding: 8px 24px;
          font-size: 12px;
          font-weight: 500;
          color: #f2f2f2;
          border-bottom: 1px solid #981214;
        }
        .header-notification-list-sub-head-title-container {
          padding: 8px 24px;
          font-size: 12px;
          font-weight: 500;
          color: #f2f2f2;
          border-bottom: 1px solid #4a4a4a;
        }
        .header-notification {
          width: 100%;
        }
      }
    }
    .username-wrapper {
      margin-left: 16px;
    }
  }
  @media only screen and (max-width: ${device.maxSizeM}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    left: 0px;
    .logo-container {
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-width: ${device.maxSizeS}) {
    padding: 0 16px;
    .logo-container {
      display: flex;
      align-items: center;
    }
  }
`
